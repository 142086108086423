<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title"> 温度月度记录</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                class="form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="年" prop="NF">
                    <el-date-picker
                        v-model="formdata.dataDic.NF"
                        type="year"
                        value-format="yyyy"
                        :disabled="!isEdit"
                        placeholder="请选择年"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="月" prop="YF">
                    <el-date-picker
                        v-model="formdata.dataDic.YF"
                        value-format="MM"
                        format="MM"
                        type="month"
                        :disabled="!isEdit"
                        placeholder="请选择月"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="温度" prop="DYQW">
                    <el-input
                        v-model="formdata.dataDic.DYQW"
                        placeholder="请输入温度"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">°C</template>
                    </el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../../0_com_js/info_mixin.js";
export default {
    name: "zr_wdydjl",
    mixins: [infoMixin],
    data() {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "8020102",
                dataDic: {
                    NF: "",
                    DYQW: "",
                    YF: "",
                },
            },
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style>
</style>
